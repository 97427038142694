import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import {ScrollContext} from 'react-router-scroll-4';
import AppLayout from "./app_layout";
import Login from "../components/login";
import PrivateRoute from "./routes/PrivateRoutes";


class App extends React.Component {

    render() {
        return (
            <div className="App">
                <BrowserRouter basename={'/'}>
                    <ScrollContext>
                        <Switch>
                            <Route path="/login" component={Login}/>
                            <PrivateRoute path="/" component={AppLayout}/>
                        </Switch>
                    </ScrollContext>
                </BrowserRouter>
            </div>
        );
    }
}

export default App;