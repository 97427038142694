import React from "react";
import firebase from "../../firebase";
import Counter from './counter'

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      counters: [],
      bookings: [],
      running_counter: 0,
    };
  }

  componentDidMount() {
    const counter_ref = firebase.database().ref("counters");
    counter_ref.on("value", (snapshot) => {
      let counters_data = snapshot.val();
      let counters = counters_data.counter;
      this.setState(
        {
          counters: counters,
        },
      );
      var running_counter = 0;
      for (let booking = 0; booking < counters.length; booking++) {
        if (counters[booking].counter_status === "Closed") {
        } else {
          running_counter++;
        }
      }
      this.setState({
        running_counter: running_counter,
      });
    });
    const bookings_ref = firebase.database().ref("bookings");
    bookings_ref.on("value", (snapshot) => {
      let bookings_data = snapshot.val();
      let bookings = bookings_data.booking;
      this.setState({
        bookings: bookings,
      });
    });
  }

  render() {
    return (
      <div  className="queue-body">
        <div className="row consulate-body">
            <div className="col-md-3 offset-md-2 booking-section">
            {this.state.bookings.map((booking)=>(
              <div className="booking-item">
                <h4 className="book-name">{booking.name}</h4>
                <h6 className="book-token">{booking.tokens}</h6>
            </div>
            ))}
            </div>
            <div className="col-md-5 counter-container">
            <div className="counter-item">
                {
                  <Counter counters={this.state.counters}/>
                }
              </div>
            </div>
        </div>
      </div>
      
    );
  }
}

export default Home;
