import React from 'react';
import logo from '../assets/images/logo/logo_100x100.png';
import firebase from "../firebase";
import auth from "./auth";

const INITIAL_STATE = {
    email: '',
    password: '',
    error: null,
};

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        const { email, password } = this.state;
        var firebase_auth = firebase.auth();
        firebase_auth.signInWithEmailAndPassword(email, password).then(result => {
            auth.login();
            this.props.history.push(`/`);
        }).catch(error => {
            console.error("Error signing in with password and email", error);
        });
        event.preventDefault();
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    render() {
        const { email, password } = this.state;

        const isInvalid = password === '' || email === '';
        return (
            <div>
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        {/* <!-- login page start--> */}
                        <div className="authentication-main">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="auth-innerright">
                                        <div className="authentication-box">
                                            <div className="text-center"><img src={logo} alt=""/></div>
                                            <div className="card mt-4">
                                                <div className="card-body">
                                                    <div className="text-center">
                                                        <h4>LOGIN</h4>
                                                        <h6>Enter your Email and Password </h6>
                                                    </div>
                                                    <form className="theme-form"  onSubmit={this.onSubmit}>

                                                        <div className="form-group">
                                                            <label className="col-form-label pt-0">Your Email</label>
                                                        <input
                                                            name="email"
                                                            value={email}
                                                            onChange={this.onChange}
                                                            type="email"
                                                            placeholder="Email Address"
                                                            className="form-control"
                                                        />
                                                        </div>
                                                            <div className="form-group">
                                                                <label className="col-form-label">Password</label>
                                                        <input
                                                            name="password"
                                                            value={password}
                                                            onChange={this.onChange}
                                                            type="password"
                                                            placeholder="Password"
                                                            className="form-control"
                                                        />
                                                            </div>
                                                        <button disabled={isInvalid} className="btn btn-primary btn-block" type="submit">
                                                            Sign In
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Login;