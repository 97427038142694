import React from 'react';

class Footer extends React.Component {

    render() {
        return (
            <div className="queue-footer">
            </div>
        );
    }
}

export default Footer;