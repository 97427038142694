import React from "react";
import { useState, useEffect } from 'react';
import audio from "../../assets/colsulate_sound.mp3";
import MultipleCounter from "./multiple_counter";
import SingleCounter from "./single_counter";

const Counter = ({ counters }) => {
  const [c, setC]= useState([])
  useEffect(()=>{
    setC(counters)
  },[counters])

  const servingCounters = c.filter(serve => serve.token_status === 'Serving');
  const callingCounters = c.filter(call_counter => call_counter.token_status === 'Calling');
  
  const renderCallingCounter = () => {
    if(callingCounters.length === 1){
      return(
        <div className="class-counter">
          <h1 className="text-center cntr-heading">Now Calling</h1>
          <SingleCounter callingCounter={callingCounters[0]} />
          <div hidden>
            <audio useRef="audio_tag" src={audio} controls autoPlay />
          </div>
        </div>
      )
    }
    else{
      return(
        <div className="class-counter">
          <h1 className="text-center cntr-heading">Now Calling</h1>
          <MultipleCounter callingCounters={callingCounters} />
          <div hidden>
            <audio useRef="audio_tag" src={audio} controls autoPlay />
          </div>
        </div>
      )
    }
  };

  const renderServingCounter = () => {
    if(servingCounters.length === 1){
      return(
        <div className="class-counter">
          <h1 className="text-center cntr-heading">Now Serving</h1>
          <SingleCounter callingCounter={servingCounters[0]} />
        </div>
      )
    }
    else{
      return servingCounters.length > 0 ? (
        <div className="class-counter">
          <h1 className="text-center cntr-heading">Now Serving</h1>
          <MultipleCounter callingCounters={servingCounters} />
        </div>
      ) : (
        <div className="class-counter">
          <h1 className="text-center cntr-heading">Now Serving</h1>
          <h2 className="text-center py-5">No counter is serving right now.</h2>
        </div>
      )
    }
  };

  const renderedHtml = () => {
    if(c.length > 1){
      if(callingCounters.length > 0){
        return renderCallingCounter();
      }
      else{
        return renderServingCounter();
      } 
    }
    else{
      return (
        <div className="">
          <h1 className="">Now Serving</h1>
          <h4 className="">No counter is serving right now.</h4>
        </div>
      )
    }
  };

  return renderedHtml();
};

export default Counter;
