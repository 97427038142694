import React from "react";

const MultipleCounter = ( {callingCounters} ) => {
    const renderedHtml = () => {
        return (
        <div className="multi-counter text-center">
          { callingCounters.map((counter)=>( 
            <div className="multi-cntr-item">
              <h3 className="text-center text-align-center verticle-middle">
                <span className="text-danger cntr-token">{counter.token}</span>
                <span> {" "}at{" "}</span>
                <span className="cntr-no">COUNTER - {" "}</span>
                <span className="text-danger cntr-no">{counter.number}</span>
              </h3>
            </div>
          ))}
        </div>
        )};
    
      return renderedHtml();
}


export default MultipleCounter;
