import React from 'react';

class Header extends React.Component {

    render() {
        return (
            <div className="queue-header"/>
        );
    }
}

export default Header;
