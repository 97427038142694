import React from "react";

const SingleCounter = ( {callingCounter} ) => {
    const renderedHtml = () => {
        return (
          <div className="text-center py-2">
            <div>
              <h1 className="sing-token-no text-danger">{callingCounter.token}</h1>
            </div>
            <div className="single-content">
              <h2 className="cntr-at">at</h2>
            </div>
            <div className="single-content">
              <h2 className="cntr-number">COUNTER - {" "}
              <span className="text-danger">{callingCounter.number}
              </span></h2>
            </div>
        </div>
        )};
    
      return renderedHtml();
}


export default SingleCounter;
