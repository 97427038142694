import firebase from "firebase";

const configuration = {
    //live
    apiKey: "AIzaSyBOqL43atCfoYwejtopgouqVgR1CX_jBjc",
    authDomain: "consulate-sydney.firebaseapp.com",
    databaseURL: "https://consulate-sydney.firebaseio.com/",

    //local
    // apiKey: "AIzaSyALdDrIKiWRWqyQo-cQ717_V1njWWEaNAA",
    // authDomain: "consulate-australia.firebaseapp.com",
    // databaseURL: "https://consulate-australia-default-rtdb.firebaseio.com/",
};

firebase.initializeApp(configuration);

export default firebase;
