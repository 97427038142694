import React from 'react';
import Loader from './common/loader';
import Home from "./home/home";
import Header from './common/header';
import Footer from './common/footer';

class AppLayout extends React.Component {

    render() {
        return (
            <div>

                <Loader/>
                <div className="page-wrapper">
                    <div className="page-body-wrapper">
                        <div className="page-body-custom">
                            {/* <Header/> */}
                            <Home/>
                            <Footer/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AppLayout;